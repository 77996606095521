import { LinkButton } from '@backstage/core-components';
import React from 'react';
import { useShadowRootElements } from '@backstage/plugin-techdocs-react';
import { parseGitHubUrl } from '../utils';
import { makeStyles } from '@material-ui/core';

const buttonStyle = makeStyles({
  margin: {
    marginLeft: 5,
  },
});

export const TechDocLlmGenLink = () => {
  const classes = buttonStyle();
  const [editLink] = useShadowRootElements(['[title^="Edit this page"]']);
  const url = (editLink as HTMLAnchorElement)?.href ?? '';
  if (!url) {
    return <></>;
  }
  const { org, repo, branch } = parseGitHubUrl(url);

  const segments = window.location.pathname.split('/').filter(Boolean);
  const [first, second, third] = segments.slice(1, 4);

  const data = {
    component: `${second}:${first}/${third}`,
    repoBranch: branch,
    repoUrl: `github.com?owner=${org}&repo=${repo}`,
  };
  const encodedData = encodeURIComponent(JSON.stringify(data));

  return (
    <>
      <LinkButton
        to={`/create/templates/default/llm-gen-doc-template?formData=${encodedData}`}
        color="primary"
        variant="contained"
        className={classes.margin}
      >
        Generate Docs with AI
      </LinkButton>
    </>
  );
};
